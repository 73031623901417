// import React from "react"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"
// import Layout from "../../components/layout"
// import BabysDev from "../../images/static-only/babys-development/Baby_development_header.jpg"

// Age Listing structure

// export default () => {
//   return (
//     <Layout>
//       <Helmet>
//         <title>Meet MJN - Explore Baby's Development</title>
//       </Helmet>
//       <main role="main" className="content-main">
        
//         <section className="hero-area -general">
//           <img src={BabysDev} alt="" />
//         </section>

//         <section className="age-listing">
//           <span className="age-listing__title">Select your baby's age</span>

//           <Link to="/event-experience/newborn" className="cta -blue age-listing__item">Newborn</Link>
//           <Link to="/event-experience/infant" className="cta -blue age-listing__item">Infant</Link>
//           <Link to="/event-experience/toddler" className="cta -blue age-listing__item">Toddler</Link>


//           <div id="root"></div>
//         </section>
//       </main>
//     </Layout>
//   )
// } 

import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout"

/* Coming soon structure */

class BabyDev extends React.Component {
  componentDidMount() {
    // Inactivity()
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Meet MJN - Explore Baby's Development</title>
          <body className="coming-soon" />
        </Helmet>
        <main role="main" className="coming-soon">
          <section className="main-item">
            <span className="main-item__title">Explore Baby's <strong>Development</strong></span>
            <span className="main-item__title -sub-title">Coming Soon</span>
            <nav className="breadcrumb -white" role="navigation">
              <Link to="/event-experience" title="Event Experience">
                <i className="fas fa-arrow-left"></i>
                Event Experience
              </Link>
            </nav>
          </section>
        </main>
      </Layout>
    )
  }
}

export default BabyDev